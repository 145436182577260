<template>
  <div
    class="font-sans min-h-screen w-full mini:max-w-80 max-w-none mx-auto p-6 mini:pt-11 box-border grid grid-rows-[auto,1fr,auto]"
  >
    <nuxt-link to="/" class="block mb-11">
      <img src="/logo.svg" class="w-11 h-11 block" alt="" />
    </nuxt-link>
    <div class="relative pb-30">
      <div
        v-show="!order"
        class="inset-0 absolute min-h-full w-full bg-white flex flex-col items-center justify-center text-center transition-all duration-800 z-50"
        :class="loading ? 'opacity-100' : 'opacity-0 pointer-events-none'"
      >
        <div>
          <Spinner :spin="true" size="large" />
        </div>
      </div>
      <Alert v-for="(error, index) in errors" :key="index" type="error">
        {{ error.detail }}
      </Alert>

      <template v-if="order">
        <slot />
      </template>
    </div>
    <div>
      <div class="flex gap-4 flex-col mini:flex-row">
        <a
          href="/refund/"
          target="_blank"
          class="text-blue-text no-underline hover:underline"
          >Refund Policy</a
        >
        <a
          href="/terms-of-sale/"
          target="_blank"
          class="text-blue-text no-underline hover:underline"
          >Terms of Sale</a
        >
        <a
          href="/conditions/"
          target="_blank"
          class="text-blue-text no-underline hover:underline"
          >Conditions of Use</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type IFetchError } from "ofetch"
  import { deserialize } from "@alchemy_cms/json_api"
  import { checkoutIncludes } from "~/lib/orderIncludes"

  const { order, loading, errors, sanitizeOrder } = useOrder()
  const { $router, $csGtm } = useNuxtApp()
  const { api } = useApi()

  useHead({
    title: "Checkout - CandleScience",
  })

  onMounted(async () => {
    if (!order.value) {
      loading.value = true
      try {
        const data = await api("/jsonapi/order", {
          params: {
            include: checkoutIncludes,
          },
        })
        const orderData = deserialize(data)
        if (orderData) {
          order.value = orderData
        }
      } catch (err) {
        sanitizeOrder(err as IFetchError)
        const typedErrors = (err as any).data.errors as JsonApiError[]
        errors.value = typedErrors
      } finally {
        loading.value = false
      }
    }
    $csGtm.trackEvent({
      event: "content-view",
      "content-name": $router.currentRoute.value.path,
      "checkout-test": "new-checkout",
    })
  })
</script>

<style>
  html,
  body {
    margin: 0;
    background: white !important;
  }

  html {
    scroll-behavior: smooth;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
